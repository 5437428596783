.form-verificadatos {
    display: grid;
    grid-template-columns: 0.5fr 0.5fr 0.5fr 0.5fr 2fr 1fr;
}

@media screen and (max-width: 800px) {
    .form-verificadatos {
        grid-template-columns: repeat(4, 0.5fr) 1fr 1fr;
    }
    .fecha {
        width: 70px;
    }
    .hora {
        width: 70px;
    }
}

@media screen and (max-width: 600px) {
    .form-verificadatos {
        grid-template-columns: repeat(4, 0.5fr) 1fr 0.5fr;
    }
    .temp-del-display {
        width: 70px;
    }
}