    .container-data {
        display: flex;
        align-items: center;
        font-size: 11px;
    }
    
    .form-registro-PruebaData {
        display: grid;
        grid-template-columns: 4fr repeat(4, 0.3fr);
    }
    
    .data-otra {
        display: grid;
        grid-template-columns: 1fr 6fr;
    }
    
    .form-registro-PruebaData label {
        display: flex;
        align-items: center;
    }
    
    .pruebadata {
        width: 20px;
        height: 20px;
        margin: 0 auto;
        display: flex;
        align-self: center;
    }
    
    @media screen and (max-width: 900px) {
        .container-data {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 11px;
        }
    }