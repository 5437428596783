.container-footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 25px 50px 25px 50px;
    margin-bottom: 20px;
    ;
}

.container-footer hr {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 2px solid rgba(0, 0, 0, .1);
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}

.container-props-footer {
    display: flex;
    justify-content: space-between;
}