.container-nav {
    background-color: #ffc000;
    display: flex;
    justify-content: space-between;
    height: 40px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.container-nav-green {
    background-color: #9BBB59;
    display: flex;
    justify-content: center;
    min-height: 40px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    text-align: center;
}

.container-nav-blue {
    background-color: #2275AA;
    display: flex;
    justify-content: center;
    text-align: center;
    color: blanchedalmond;
    height: 40px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.container-nav-red {
    background-color: #FF0000;
    display: flex;
    justify-content: center;
    min-height: 40px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: blanchedalmond;
    font-size: 10px;
    font-weight: 300;
    text-align: center;
}

.container-info {
    display: flex;
    align-items: center;
    width: 300px;
    justify-content: space-around;
    font-size: 12px;
}

.container-fecha {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 200px;
}