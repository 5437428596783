        .form-InfoFichaTecnica41 {
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            text-align: center;
        }
        
        .form-InfoFichaTecnica41 span {
            border: black 1px solid;
            min-height: 20px;
            width: 99%;
        }
        
        .container42 {
            display: grid;
            grid-template-columns: 1fr 2fr 3fr;
        }
        
        .container42 span {
            border: black 1px solid;
        }
        
        .container42 div {
            border: black 1px solid;
        }
        
        .form-FichaTecnica42 {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
        
        .form-FichaTecnica42 span {
            display: flex;
            flex-direction: column;
            text-align: center;
            align-content: center;
            justify-content: center;
        }
        
        .form-FichaTecnica42 h5 {
            height: 53px;
        }
        
        .container-sensores42 {
            display: flex;
            flex-direction: column;
            text-align: center;
        }
        
        .form-Sensores42 {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
        }
        
        .container-Alarmas42 {
            display: flex;
            flex-direction: column;
            text-align: center;
        }
        
        .form-Alarmas42 {
            display: grid;
            grid-template-columns: repeat(6, 1fr);
        }
        
        .container43 {
            display: grid;
            grid-template-columns: 1fr 2fr;
            .form-43 {
                display: grid;
                grid-template-columns: 1fr 1fr;
                text-align: center
            }
            .form-43 input {
                width: 99%;
            }
            .progalarmas43 {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-content: center;
                text-align: center;
            }
            .form-alarmas43 {
                display: grid;
                grid-template-columns: repeat(6, 1fr);
            }
            .inputs43 {
                display: grid;
                justify-items: center;
            }
        }
        
        @media screen and (max-width: 1000px) {
            .form-InfoFichaTecnica41 {
                grid-template-columns: repeat(3, 1fr);
            }
            .container42 {
                display: block;
            }
            .form-FichaTecnica42 input {
                width: 90%;
            }
            .form-FichaTecnica42 h5 {
                height: auto;
            }
            .container43 {
                display: block;
            }
        }
        
        @media screen and (max-width: 700px) {
            .form-InfoFichaTecnica41 {
                grid-template-columns: repeat(2, 1fr);
            }
            .form-InfoFichaTecnica input {
                width: 95%;
            }
            .container43 {
                display: block;
                .form-alarmas43 {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                }
            }
        }