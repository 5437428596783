.recomendaciones {
    display: grid;
    grid-template-columns: 1fr 4fr;
}

.form-falloenergia {
    display: grid;
    grid-template-columns: 3fr 1fr 1fr;
}

.container-fechahora {
    width: 99%;
    display: grid;
    grid-template-columns: 2fr 1fr;
}

@media screen and (max-width: 800px) {
    .form-falloenergia {
        grid-template-columns: 3fr 0.5fr 0.5fr;
    }
    .form-falloenergia input {
        width: 80px;
    }
}