#root {
    margin: 0;
    padding: 0;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    min-width: 380px;
}

#root button {
    min-width: 80px;
    height: 30px;
    border-radius: 5px;
    box-shadow: cornflowerblue 3px 4px;
    margin: 10px;
    padding: 5px;
}

#root label {
    width: 99%;
    border: 1px solid black;
}

.container-certificadoOP {
    min-height: 1370px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 12px;
    margin-bottom: 20px;
}

@media screen and (max-width: 900px) {
    .container-certificadoOP p {
        display: none;
    }
}