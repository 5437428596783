.container-certOP {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.container-contenico {
    display: flex;
    width: 90%;
    align-content: space-around;
    justify-content: flex-start;
}