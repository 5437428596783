.container-certOP {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.container-title-certOP {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    padding-bottom: 30px;
}

.form-clienteop {
    width: 90%;
    display: grid;
    grid-template-columns: 1fr 3fr;
}

.form-equipoOp {
    width: 90%;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 2fr;
    margin-bottom: 20px;
}

.containeraprovacion {
    width: 90%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

.containeraprovacion form {
    width: 95%;
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 1000px) {
    .containeraprovacion {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 700px) {
    .form-equipoOp {
        grid-template-columns: 1fr 2fr;
    }
    .containeraprovacion {
        display: block;
    }
}