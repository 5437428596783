.container6 {
    font-size: 16px;
    line-height: 30px;
    padding: 15px;
}

.container6 dt {
    font-weight: bold;
}

.container61 {
    display: flex;
    justify-content: center;
    align-content: center;
    text-align: center;
    font-size: 16px;
    line-height: 30px;
    padding: 15px;
}

.container61 dt {
    font-weight: bold;
    margin-bottom: 10px;
}

.containerimgs6 {
    display: flex;
    justify-content: space-between;
    align-content: center;
}

.containerimgs6 dd {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.containerimgs6 img {
    width: 50px;
    height: 70px;
}

@media screen and (max-width: 600px) {
    .containerimgs6 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-content: center;
    }
}