.containertabla1 {
    display: flex;
    flex-direction: column;
}

.containertabla1 span {
    display: flex;
    flex-direction: column;
}

.containertabla1 form {
    display: grid;
    grid-template-columns: 0.5fr 1fr 1fr;
}

.containertabla1 label {
    height: 25px;
}

.containertabla1 input {
    height: 21px;
}

.container62 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: space-around;
    text-align: left;
    font-size: 16px;
    line-height: 20px;
    padding: 15px;
}

.container62 dt {
    font-weight: bold;
    margin-bottom: 10px;
}

.sensor {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
}

.sensor span {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 10px;
}

.sensor label {
    height: 21px;
}

.sensor input {
    height: 17px;
    width: 99%;
}

.cumpleSensor {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.cumpleSensor span {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.cumpleSensor label {
    display: flex;
    justify-content: space-evenly;
}

.cumpleSensor input {
    width: 10px;
}

@media screen and (max-width: 1000px) {
    .sensor {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
    }
}

@media screen and (max-width: 600px) {
    .containertabla1 label {
        height: 25px;
        width: 98%;
    }
    .containertabla1 input {
        height: 21px;
        width: 99%;
    }
}