.container-heaader {
    display: grid;
    grid-template-columns: 15% 65% 20%;
    border: 2px solid black;
    height: 100px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.container-image {
    display: flex;
    align-items: center;
    border: 2px solid black;
    height: 96px;
}

.container-image img {
    width: 95%;
}

.container-title {
    background-color: #0070c0;
    height: 96px;
    border: 2px solid black;
}

.title-procesos {
    height: 20px;
    border: 2px solid black;
    margin: 0;
    padding: 10px 0px 10px 0px;
    text-align: center;
    font-size: 18px;
    color: blanchedalmond;
}

.title-registro {
    height: 20px;
    margin: 0;
    padding: 10px 0px 10px 0px;
    font-size: 18px;
    text-align: center;
    padding-bottom: 15px;
    color: blanchedalmond;
}

.container-paginado h4 {
    margin: 0;
    padding-top: 5px;
    border: 2px solid black;
    height: 30px;
    font-size: 14px;
    text-align: center;
}

.container-paginado h2 {
    font-size: 12px;
    text-align: center;
    padding-top: 5px;
}

@media screen and (max-width: 700px) {
    .title-registro {
        font-size: 14px;
    }
    .title-procesos {
        font-size: 14px;
    }
    .container-paginado h2 {
        font-size: 9px;
    }
    .container-paginado h4 {
        font-size: 11px;
    }
}

@media screen and (max-width: 500px) {
    .title-registro {
        font-size: 12px;
    }
    .title-procesos {
        font-size: 12px;
    }
    .container-paginado h2 {
        font-size: 8px;
    }
    .container-paginado h4 {
        font-size: 8px;
    }
}