.containter-InfoTecnica {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    font-size: 10px;
    gap: 5px;
}

.containter-InfoTecnica div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.containter-InfoTecnica div form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5px;
}

.form-registro-InfoTecnica1 {
    display: grid;
    grid-template-columns: 1fr 3fr;
    height: 80px;
    margin-top: 10px;
    gap: 5px;
}

@media screen and (max-width: 1000px) {
    .containter-InfoTecnica {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        font-size: 12px;
    }
    .containter-InfoTecnica div form {
        
        width: 99%;
    }
}

@media screen and (max-width: 600px) {
    .containter-InfoTecnica {
        display: block;
        font-size: 12px;
    }

    .containter-InfoTecnica div form {
        
        width: 99%;
    }
    .form-registro-InfoTecnica1 {
        display: flex;
        flex-direction: column;
    }
    .form-registro-InfoTecnica1 input {
        height: 100px;
    }
}