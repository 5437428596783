.containter-TomaDatos {
    display: grid;
    grid-template-columns: 1fr 4fr;
}

.containter-TomaDatos div {
    display: flex;
    align-items: center;
    font-size: 14px;
}

.form-registro-TomaDatos {
    display: grid;
    grid-template-columns: 5% 95%;
    gap: 7px;
}

.form-registro-TomaDatos input {
    width: 20px;
    height: 20px;
    padding: 5px;
    border-radius: 15px;
    border: solid 1px black;
}
.container-tomadato1 {
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.container-tomadato1 h3 {
    background-color: yellowgreen;
}
.form-registro-TomaDatos1 {
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.form-registro-TomaDatos1 label {
    display: flex;
    align-items: center;
}
.form-registro-Date {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
}

.container-datos-img{
    display: grid;
    grid-template-columns: 1fr 3fr;
}
.container-datos-img h3 {
    min-height: 50px;
}
.form-registro-Datalogers {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 21px;
}
.form-registro-Producto{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}
.container-imgs {
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.container-imgs h3 {
    min-height: 50px;
}
.container-imgs div {
  display: block;
  text-align: center;
}
.container-imgs span {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.porccarga {
    width: 99%;
}
@media screen and (max-width: 1000px) {
    .containter-TomaDatos {
        display: block;
    }
    .containter-TomaDatos h3 {
        margin: 0 auto;
    }
    .form-registro-TomaDatos {
        grid-template-columns: 5% 95%;
    }
    .form-registro-TomaDatos input {
        margin-right: 10px;
    }
    .container-tomadato1 {
        display: block;
    }
 
    .form-registro-TomaDatos1 {
        height: 80px;
    }

    .form-registro-TomaDatos1 label {
        display: flex;
        align-items: center;
    }
    .form-registro-Date {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
    }
    .form-registro-Producto {
        display: grid;
        grid-template-columns: 1fr 1fr;
        font-size: 12px;
    }

}

@media screen and (max-width: 700px) {
    .form-registro-Date {
        display: flex;
        flex-direction: column;

    }
    .container-datos-img{
        display: block
    }
    .form-registro-Datalogers {
       
        gap: 2px;
    }
   
}
@media screen and (max-width: 500px) {
    .container-imgs {
        display: block;
     }
   
}
