.container-form-dataloger {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
}

.container-form-dataloger form {
    display: grid;
}

@media screen and (max-width: 900px) {
    .container-form-dataloger {
        display: block;
    }
    .form-InfoFichaTecnica {
        grid-template-columns: repeat(3, 1fr);
    }
}