.container-registro {
    font-size: 10px;
}

.container-registro input {
    height: 20px;
}

.container-registro label {
    width: 99%;
    border: 1px solid black;
    height: 21px;
}

.form-registro-InfoEquipos {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

.form-registro-InfoEquipos span {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.form-registro-InfoEquipos span input {
    width: 15px;
    height: 15px;
}

.form-registro-InfoEquipos2 {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
}

.form-registro-InfoEquipos3 {
    display: grid;
    grid-template-columns: 0.5fr 2fr;
}

.form-registro-InfoEquipos3 label {
    height: 51px;
}

.form-registro-InfoEquipos3 input {
    height: 50px;
}

@media screen and (max-width: 1000px) {
    .form-registro-InfoEquipos {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
    .form-registro-InfoEquipos2 {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (max-width: 700px) {
    .form-registro-InfoEquipos {
        display: grid;
        grid-template-columns: 1fr;
    }
    .form-registro-InfoEquipos2 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}