.form-registro {
    display: grid;
    grid-template-columns: 0.5fr 2fr 0.5fr 2fr 0.5fr 2fr;
    border: 1px solid black;
}

.form-registro label {
    width: 99%;
    border: 1px solid black;
}

@media screen and (max-width: 1000px) {
    .form-registro {
        grid-template-columns: 0.5fr 2fr 0.5fr 2fr;
    }
}

@media screen and (max-width: 600px) {
    .form-registro {
        grid-template-columns: 1fr 2fr;
    }
}