.form-encuesta {
    display: grid;
    grid-template-columns: 1fr 1fr;
    font-size: 12px;
    border: 1px solid black;
}

.form-encuesta label {
    width: 99%;
    border: 1px solid black;
}

.containerFinal {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border: 1px solid black;
}

.containerFinal h2 {
    font-size: 16px;
    border: 1px solid black;
    height: 39px;
}

.form-registro-encuesta {
    display: grid;
    grid-template-columns: 1fr 2fr;
    border: 1px solid black;
}

.form-registro label {
    width: 99%;
    border: 1px solid black;
}

.observacionesCliente {
    width: 98%;
    height: 62px;
}

@media screen and (max-width: 600px) {
    .containerFinal {
        display: block;
    }
}