.container-certOP {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.container-pruebas {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.form-Pruebasop {
    width: 90%;
    display: grid;
    grid-template-columns: 1fr 3fr;
    padding-top: 40px;
}

.form-resp-pruebaop {
    width: 90%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}