.container-home {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 50px;
    background: #0070c0;
    color: blanchedalmond;
    text-align: center;
}

.container-home a {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    font-weight: 700;
    color: blanchedalmond;
}