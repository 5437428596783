.container64 dt {
    font-weight: bold;
    margin-bottom: 10px;
}

.seriesensores {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
}

.noSerieSensor {
    display: flex;
    flex-direction: column;
}

.seriesensores input {
    width: 96%;
    height: 40px;
}

.periodoEvaluado {
    display: flex;
    flex-direction: column;
}

.labelsperiodo {
    display: flex;
    flex-direction: column;
}

.labelsperiodo label {
    width: 99%;
    height: 21px;
}

.tempMax {
    display: flex;
    flex-direction: column;
}

.tempMax input {
    width: 96%;
    height: 17px;
}

.TempMinSensores {
    display: flex;
    flex-direction: column;
}

.TempMinSensores input {
    width: 97%;
    height: 17px;
}

.cumpleSensor14 {
    display: flex;
    flex-direction: column;
}

.containerCumple {
    display: flex;
    flex-direction: column;
}

.containerCumple span {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.containerCumple label {
    width: 97%;
    height: 21px;
    display: flex;
    align-items: baseline;
}

.containerCumple input {
    height: 15px;
    margin-bottom: 15px;
}