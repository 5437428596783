.container-tittles {
    display: grid;
    grid-template-columns: 1fr 5fr;
}

.container-formdesempeño {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 20px 0px 20px 0px;
}

.container-formdesempeño form {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.container-RD {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding-bottom: 20px;
}

.container-RD form {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.containerTec {
    display: flex;
    flex-direction: column;
}

.containerTec form {
    display: grid;
    grid-template-columns: 1fr 0.5fr 1fr 0.5fr 1fr;
}

.observacioncargaprod {
    min-height: 40px;
    width: 99%;
    padding: 5px 0px 10px 0px;
    margin: 5px 0px 10px 0px;
}

.notastec {
    min-height: 30px;
    width: 99%;
    padding: 10px 0px 5px 0px;
    margin: 10px 0px 5px 0px;
}

.boton-final-registro {
    width: 200px;
    margin: 0 auto;
}

@media screen and (max-width: 1100px) {
    .container-formdesempeño {
        grid-template-columns: 1fr 1fr;
    }
    .container-RD {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 700px) {
    .container-formdesempeño {
        display: block;
    }
    .container-RD {
        display: block;
    }
}